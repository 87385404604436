import { AiOutlineDashboard, AiOutlineWallet } from "react-icons/ai";
import { FiUser, FiSettings } from "react-icons/fi";
import { GiTakeMyMoney } from "react-icons/gi";

import { MdOutlineSupportAgent } from "react-icons/md";
export const menus = [
  // ************************************* common dashboard menu ************************//
  {
    id: "337fwedkf",
    menu: "dashboard",
    icon: <AiOutlineDashboard />,
    route: "/dashboard",
    permission: ["user", "admin"],
  },
  // ************************************* admin dashboard menu ************************//
  {
    id: "7dfsegrr8srf",
    menu: "Member",
    icon: <FiUser />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83agr89r4ifd",
        menu: "All Member",
        route: "/dashboard/member/all-member",
        permission: ["admin"],
      },
      {
        id: "838dga9r4ifd",
        menu: "Active Member",
        route: "/dashboard/member/active-member",
        permission: ["admin"],
      },
      {
        id: "838dga9ifd",
        menu: "Blocked Member",
        route: "/dashboard/member/blocked-member",
        permission: ["admin"],
      },
      {
        id: "838dga93up",
        menu: "User PIN",
        route: "/dashboard/member/user-pin",
        permission: ["admin"],
      },
      {
        id: "1458s8e88wer",
        menu: "Team Statistics",
        route: "/dashboard/member/team-statistics",
        permission: ["admin"],
      },
      {
        id: "1458s8e88werrer",
        menu: "Address History",
        route: "/dashboard/member/address-history",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "7dfsrs8srf",
    menu: "Deposit Report",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83bhi9r4ixd",
        menu: "Today's Deposits 💰",
        route: "/dashboard/deposit/today-deposits",
        permission: ["admin"],
      },
      {
        id: "83bhi9r4ifd",
        menu: "All Transaction",
        route: "/dashboard/deposit/all-deposit",
        permission: ["admin"],
      },
      {
        id: "838dsoifd",
        menu: "Successful Report",
        route: "/dashboard/deposit/successful-deposit",
        permission: ["admin"],
      },
      {
        id: "8shbga9ifd",
        menu: "Rejected Report",
        route: "/dashboard/deposit/rejected-deposit",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "7dfskdju8srf",
    menu: "Manage Deposit",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83oeieuej9r4ifd",
        menu: "Manage Deposit",
        route: "/dashboard/deposit/manage-deposit",
        permission: ["admin"],
      },
      {
        id: "83ieueyoifd",
        menu: "Manage Deposit History",
        route: "/dashboard/deposit/manage-deposit-history",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "7dfsrs8srftopup",
    menu: "Topup Report",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83bhi9r4ifdtpup",
        menu: "All Topup",
        route: "/dashboard/topup/all-top-up-history",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "ajmd38srf",
    menu: "AutoWithdraw 3.0",
    icon: <AiOutlineWallet />,
    permission: ["admin"],
    dropdown: [
      {
        id: "zajei9r4gfd",
        menu: "💸⚡AutoWithdraw",
        route: "/dashboard/Withdraw/auto-withdraw",
        permission: ["admin"],
      },
      {
        id: "yaj4x9r4ifd",
        menu: "📈💰Profit Bucket List",
        route: "/dashboard/Withdraw/profit-bucket-list",
        permission: ["admin"],
      },
      {
        id: "xaj4x9r4ifd",
        menu: "🏦📥Investment Bucket List",
        route: "/dashboard/Withdraw/investment-bucket-list",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "ajjd38srf",
    menu: "withdraw 2.0",
    icon: <AiOutlineWallet />,
    permission: ["admin"],
    dropdown: [
      {
        id: "dajei9r4gfd",
        menu: "💰✨Current Profit Withdraw",
        route: "/dashboard/Withdraw/current-profit-withdraw",
        permission: ["admin"],
      },
      {
        id: "daj4x9r4ifd",
        menu: "✨📤Current Investment Withdraw",
        route: "/dashboard/Withdraw/current-investment-withdraw",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "ajjdrs8srf",
    menu: "withdraw",
    icon: <AiOutlineWallet />,
    permission: ["admin"],
    dropdown: [
      {
        id: "dajeidr4ifd",
        menu: "Todays Withdraw 💸",
        route: "/dashboard/Withdraw/todays-withdraw",
        permission: ["admin"],
      },
      {
        id: "dajei9r4ifd",
        menu: "All Withdraw",
        route: "/dashboard/Withdraw/all-withdraw",
        permission: ["admin"],
      },
      {
        id: "ajobeoifd",
        menu: "Successful Withdraw",
        route: "/dashboard/Withdraw/successful-withdraw",
        permission: ["admin"],
      },
      {
        id: "aubia9ifd",
        menu: "Rejected Withdraw",
        route: "/dashboard/Withdraw/rejected-withdraw",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "4343qf3q2f",
    menu: "earning",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "34qqf43gq3hq",
        menu: "Level Income",
        route: "/dashboard/earnings/level-income",
        permission: ["admin"],
      },
      {
        id: "f5sf5s8fs8f",
        menu: "Roi income",
        route: "/dashboard/earnings/staking-income",
        permission: ["admin"],
      },
      {
        id: "f5sf5s8fs8frank",
        menu: "Rank income",
        route: "/dashboard/earnings/rank-income",
        permission: ["admin"],
      },
      {
        id: "7885s8sf8sf",
        menu: "Bonus income",
        route: "/dashboard/earnings/bonus-income",
        permission: ["admin"],
      },
      // hide reward
      {
        id: "f5sf5s8fs8fnaj3w",
        menu: "Reward",
        route: "/dashboard/earnings/admin-reward",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "nf4au4awg43",
    menu: "Support",
    icon: <MdOutlineSupportAgent />,
    permission: ["admin"],
    dropdown: [
      {
        id: "98ay9uva",
        menu: "Contact Us",
        route: "/dashboard/contact-us",
        permission: ["admin"],
      },
      {
        id: "30ht9fwa",
        menu: "Ticket Data",
        route: "/dashboard/ticket-data",
        permission: ["admin"],
      },
      {
        id: "30ht9fca",
        menu: "Create News",
        route: "/dashboard/create-update",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "sf4s8f7ser",
    menu: "Setting",
    icon: <FiSettings />,
    permission: ["admin"],
    dropdown: [
      {
        id: "f4s8fe8r8",
        menu: "Change Password",
        route: "/dashboard/change-password",
        permission: ["admin"],
      },
      {
        id: "f5sfsf8sf",
        menu: "Change Email",
        route: "/dashboard/change-email",
        permission: ["admin"],
      },
      // {
      //   id: "sf4se8r9w",
      //   menu: "Popup Image",
      //   route: "/dashboard/popup-image",
      //   permission: ["admin"],
      // },
      {
        id: "4f7sfs8fs8f",
        menu: "PDF Controller",
        route: "/dashboard/pdf-controller",
        permission: ["admin"],
      },
      {
        id: "4f7sfs8fskk",
        menu: "Gallery",
        route: "/dashboard/gallery",
        permission: ["admin"],
      },
      {
        id: "4f7sfs8fs00",
        menu: "Manage Photos",
        route: "/dashboard/gallery/manage-photos",
        permission: ["admin"],
      },
      {
        id: "4f7sfs8fs10",
        menu: "Manage Videos",
        route: "/dashboard/gallery/manage-videos",
        permission: ["admin"],
      },
    ],
  },
];
