import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";


// useTodayDepositeHistoryQuery,
// useAllDepositeHistoryQuery,

export const depositeApi = createApi({
  reducerPath: "depositeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("safe_secure_token"));
      return headers;
    },
  }),
  // ?pageNo=${query?.pageNo}&pagePerShow=${query?.pagePerShow}
  tagTypes: ["adminDeposite", "userDeposit", "manageDeposit"], // automatic-data fetching
  endpoints: (builder) => ({
    // admin
    todayDepositeHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_today_deposits?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    allDepositeHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_all_deposits?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    editDepositStatus: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_deposit_status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminDeposite"],
    }),
    updateDepositRemark: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_deposit_remark",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminDeposite"],
    }),
    completedDepositeHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_success_deposits?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    canceledDepositeHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_rejected_deposits?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    getManageDeposit: builder.query({
      query: (query) =>
        `/api/v1/private/get_manage_deposit?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["manageDeposit"], // automatic-data fetching
    }),
    getAllManageDepositHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_all_manage_deposit_history?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["manageDeposit"], // automatic-data fetching
    }),
    updateManageDepositAmountAdmin: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_manage_deposit_Amount",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["manageDeposit"],
    }),
    loginManageDepositAdmin: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/login_Manage_Deposit_Auth",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useTodayDepositeHistoryQuery,
  useAllDepositeHistoryQuery,
  useCompletedDepositeHistoryQuery,
  useCanceledDepositeHistoryQuery,
  useUpdateDepositRemarkMutation,
  useEditDepositStatusMutation,
  useGetManageDepositQuery,
  useUpdateManageDepositAmountAdminMutation,
  useGetAllManageDepositHistoryQuery,
  useLoginManageDepositAdminMutation,
} = depositeApi;
